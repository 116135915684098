import React from "react";
import "./footer.css";
import logo from "../../assets/FooterSection/agent-label-logo.svg";
const FooterSection = () => {
  return (
    <footer>
      <div className="footer__wrapper">
        <div className="container">
          <div className="footer-inner">
            <div className="footer-items">
              <div className="footer-logo">
                <img src={logo} alt="agent-sketch-logo" />
              </div>
              <div className="row">
                <div className="list-name">
                  <p>
                  Empower your team with agent-driven tools and automation to enhance model development and improve annotation accuracy. 
                  </p>
                </div>
              </div>
            </div>
            <div className="footer-end">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-sm-7 col-md-7 col-lg-7">
                  <div className="footer-links">
                    <p>Legal</p>
                    <p>Privacy Policy</p>
                    <p>Terms & Conditions</p>
                  </div>
                </div>
                <div className="col-sm-5 col-md-5 col-lg-5 copyright">
                  <p>Copyright 2025. All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
